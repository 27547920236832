import DataProductLayout from 'Layout/DataProductLayout';
import DataProductSideBarLayout from 'Layout/DataProductSideBarLayout';
import PublishMappingSpecLayout from 'Layout/PublishMappingSpecLayout';
import DataProducts from './DataProducts';
import AddDataProducts from './Components/AddDataProducts/AddDataProducts';
import PublishMappingSpec from './Components/PublishMappingSpec/PublishMappingSpec';
import TrialVisitsPreview from './Components/RuleEditor/SelectDataSource/Components/PreviewComponents/TrialVisitsPreview';
import TrialArmsPreview from './Components/RuleEditor/SelectDataSource/Components/PreviewComponents/TrialArmsPreview';
import TimePointsPreview from './Components/RuleEditor/SelectDataSource/Components/PreviewComponents/TimePointsPreview';
import TrialElementsPreview from './Components/RuleEditor/SelectDataSource/Components/PreviewComponents/TrialElementsPreview';
import CodeListsPreview from './Components/RuleEditor/SelectDataSource/Components/PreviewComponents/CodeListsPreview';
import TrialIEPreview from './Components/RuleEditor/SelectDataSource/Components/PreviewComponents/TrialIEPreview';
import PreviewPage from '../../Layout/ReferenceDataPreviewPage/PreviewPage';
import { RuleEditorSummaryLayout } from '../../Layout/RuleEditorSummaryLayout/RuleEditorSummaryLayout';
import ReferenceData from '../../Pages/ProductDesigner/Components/RuleEditor/SelectDataSource/Components/ReferenceData';
import SelectDataSource from '../../Pages/ProductDesigner/Components/RuleEditor/SelectDataSource/Components/SelectDataSource';
import SupplementalQualifier from '../../Pages/ProductDesigner/Components/RuleEditor/SelectDataSource/Components/SupplementalQualifier';
import { MasteringRulesProvider } from '../../Pages/ProductDesigner/Components/RuleEditor/SelectDataSource/MasteringRulesProvider';
import MappingSpec from '../../Pages/ProductDesigner/Components/RuleEditor/SelectDataSource/MappingSpec';
import DomainPreview from './Components/Preview/DomainPreview';
import DomainPreviewLayout from 'Layout/DomainPreviewLayout/DomainPreviewLayout';
import { BulkEditLayout } from 'Layout/BulkEditLayout/BulkEditLayout';
import BulkEditPage from 'Layout/BulkEditLayout/BulkEditPage';
import { SupplementalQualifierPreview } from './Components/RuleEditor/SelectDataSource/Components/PreviewComponents';
import CtpSvtPreview from './Components/RuleEditor/SelectDataSource/Components/PreviewComponents/CtpSvtPreview';
import ApplyRuleset from './Components/RuleEditor/SelectDataSource/Components/ApplyRuleset/ApplyRuleset';
import { DomainRulesProvider } from './Components/RuleEditor/SelectDataSource/DomainRules/DomainRulesProvider';
import DomainRules from './Components/RuleEditor/SelectDataSource/MDSDomainRules/DomainRules';
import PostSQLEditor from './Components/RuleEditor/SelectDataSource/Components/PostSQLComponents/PostSQLEditor';
import { VLCRules } from './Components/RuleEditor/VLCRules/VLCRules';
import VLCRulesPreview from './Components/RuleEditor/VLCRules/VLCRulesPreview/VLCRulesPreview';

const routes = [
  {
    path: '/product-designer/rule-editor/:id/additional-domain-rules',
    component: PostSQLEditor,
    layout: RuleEditorSummaryLayout,
    exact: true,
    authentication: true
  },
  {
    path: '/product-designer',
    component: DataProducts,
    layout: DataProductLayout,
    exact: true,
    authentication: true
  },
  {
    path: '/product-designer/add-data-product',
    component: AddDataProducts,
    layout: DataProductSideBarLayout,
    exact: true,
    authentication: true
  },
  {
    path: '/product-designer/edit-data-product',
    component: AddDataProducts,
    layout: DataProductSideBarLayout,
    exact: true,
    authentication: true
  },
  {
    path: '/product-designer/manage-data-pipeline-version',
    component: PublishMappingSpec,
    layout: PublishMappingSpecLayout,
    exact: true,
    authentication: true
  },
  {
    path: '/manage-data-pipeline/manage-data-pipeline-version',
    component: PublishMappingSpec,
    layout: PublishMappingSpecLayout,
    exact: true,
    authentication: true
  },
  {
    path: '/product-designer/rule-editor/:id/trial-visit',
    component: TrialVisitsPreview,
    layout: PreviewPage,
    exact: true,
    authentication: true
  },
  {
    path: '/product-designer/rule-editor/:id/time-point',
    component: TimePointsPreview,
    layout: PreviewPage,
    exact: true,
    authentication: true
  },
  {
    path: '/product-designer/rule-editor/:id/trial-arm',
    component: TrialArmsPreview,
    layout: PreviewPage,
    exact: true,
    authentication: true
  },
  {
    path: '/product-designer/rule-editor/:id/trial-element',
    component: TrialElementsPreview,
    layout: PreviewPage,
    exact: true,
    authentication: true
  },
  {
    path: '/product-designer/rule-editor/:id/code-list',
    component: CodeListsPreview,
    layout: PreviewPage,
    exact: true,
    authentication: true
  },
  {
    path: '/product-designer/rule-editor/:id/trial-IE',
    component: TrialIEPreview,
    layout: PreviewPage,
    exact: true,
    authentication: true
  },
  {
    path: '/product-designer/rule-editor/:id/ctp-svt',
    component: CtpSvtPreview,
    layout: PreviewPage,
    exact: true,
    authentication: true
  },
  {
    path: '/product-designer/rule-editor/:id/reference-data',
    component: ReferenceData,
    layout: RuleEditorSummaryLayout,
    exact: true,
    authentication: true
  },
  {
    path: '/product-designer/rule-editor/:id/data-sources',
    component: SelectDataSource,
    layout: RuleEditorSummaryLayout,
    exact: true,
    authentication: true
  },
  {
    path: '/product-designer/rule-editor/:id/apply-ruleset',
    component: ApplyRuleset,
    layout: RuleEditorSummaryLayout,
    exact: true,
    authentication: true
  },
  {
    path: '/product-designer/rule-editor/:id/supp-qual',
    component: SupplementalQualifier,
    layout: RuleEditorSummaryLayout,
    exact: true,
    authentication: true
  },
  {
    path: '/product-designer/rule-editor/:id/supp-qual/preview',
    component: SupplementalQualifierPreview,
    layout: PreviewPage,
    exact: true,
    authentication: true
  },
  {
    path: '/product-designer/rule-editor/:id/mastering-rules',
    component: MasteringRulesProvider,
    layout: RuleEditorSummaryLayout,
    exact: true,
    authentication: true
  },
  {
    path: '/product-designer/rule-editor/:id/domain-rules',
    component: DomainRulesProvider,
    layout: RuleEditorSummaryLayout,
    exact: true,
    authentication: true
  },
  {
    path: '/product-designer/rule-editor/:id/vlc-rules',
    component: VLCRules,
    layout: RuleEditorSummaryLayout,
    exact: true,
    authentication: true
  },
  {
    path: '/product-designer/rule-editor/:id/vlc-rules-preview',
    component: VLCRulesPreview,
    layout: DomainPreviewLayout,
    exact: true,
    authentication: true
  },
  {
    path: '/product-designer/rule-editor/:id/mds-domain-rules',
    component: DomainRules,
    layout: RuleEditorSummaryLayout,
    exact: true,
    authentication: true
  },
  {
    path: '/product-designer/rule-editor/:id/batch-edit',
    component: BulkEditPage,
    layout: BulkEditLayout,
    exact: true,
    authentication: true
  },
  {
    path: '/product-designer/rule-editor/:id/domain-preview',
    component: DomainPreview,
    layout: DomainPreviewLayout,
    exact: true,
    authentication: true
  },
  {
    path: '/product-designer/rule-editor/:id/mds-domain-preview',
    component: DomainPreview,
    layout: DomainPreviewLayout,
    exact: true,
    authentication: true
  },
  {
    path: '/product-designer/rule-editor/:id/subject-setting-preview',
    component: DomainPreview,
    layout: DomainPreviewLayout,
    exact: true,
    authentication: true
  },
  {
    path: '/product-designer/rule-editor/:id/visit-setting-preview',
    component: DomainPreview,
    layout: DomainPreviewLayout,
    exact: true,
    authentication: true
  },
  {
    path: '/product-designer/rule-editor/:id/create-mapping-spec',
    component: MappingSpec,
    layout: RuleEditorSummaryLayout,
    exact: true,
    authentication: true
  }
];
export default routes;
