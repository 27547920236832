/*eslint-disable */
import React, { useState, useEffect, useMemo, useRef } from 'react';
import Typography from 'apollo-react/components/Typography';
import {
  Grid,
  Box,
  Tooltip,
  Switch,
  Divider,
  Tabs,
  Tab,
  Card,
  Checkbox,
  CardContent,
  IconButton
} from 'apollo-react';
import { neutral8, neutral2 } from 'apollo-react/colors';
import ChevronRight from 'apollo-react-icons/ChevronRight';
import ColumnVariable from '../Components/ColumnVariable';
import { makeStyles } from '@mui/styles';
import { Notification } from 'Components/Common/Notification';
import { FixedSizeList as List } from 'react-window';
import ConfigSection from '../Components/ConfigSection';
import { Info } from 'apollo-react-icons';
import Container from 'apollo-react/components/Container';
import ErrorIcon from 'Images/error.svg';
import _ from 'lodash';
import { useSelector } from 'react-redux';

const useStyles = makeStyles({
  checkBoxClass: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'left'
  },
  columnsHeader: {
    backgroundColor: '#F6F7FB',
    height: '44px',
    display: 'flex'
  },
  domainColumnCard: {
    height: '450px',
    borderRadius: '8px',
    padding: '0'
  },
  additionalDomainColumnCard: {
    height: '450px',
    borderRadius: '8px',
    padding: '0',
    backgroundColor: neutral2
  },
  validationErrorsContainer: {
    maxWidth: 'none',
    maxHeight: '184px',
    padding: '14px',
    borderRadius: '5px',
    borderColor: '#E3E3E3',
    backgroundColor: '#FCEBEB'
  },
  errorTitle: {
    fontFamily: 'Proxima Nova',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '20px',
    color: '#E20000',
    textAlign: 'left'
  },
  errorContent: {
    fontFamily: 'Proxima Nova',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '24px',
    textAlign: 'left',
    color: '#444444'
  }
});

const AdditionalDomainsMessage = () => {
  return (
    <Grid style={{ padding: '10px' }}>
      <Notification
        variant={'info'}
        message={
          'Column details are not available for Additional Domains, as these are the new domains created using a SQL query during mapping and actual domains are not created at this point to pull the column details.'
        }
      />
    </Grid>
  );
};

const DOMAIN_TAB = {
  'User Defined': 0,
  'System Defined': 1,
  'Additional Domains': 2
};

const ColumnRow = ({
  style,
  index,
  data: {
    localColumns,
    userDefinedVarCount,
    rowData,
    readOnly,
    columnNameErrorList,
    onMoveVariableRow,
    handleSelectVariableCheckbox,
    handleColumnNameChange,
    moveToIndexState,
    setMoveToIndexState,
    draggedRowIndex,
    setDraggedRowIndex
  }
}) => (
  <ColumnVariable
    {...localColumns[index]}
    key={`${localColumns.map((column) => column.originalColumnName)}`}
    style={style}
    index={index}
    userDefinedVarCount={userDefinedVarCount}
    rowData={rowData}
    readOnly={readOnly}
    columnNameErrorList={columnNameErrorList}
    onMoveVariableRow={onMoveVariableRow}
    handleSelectVariableCheckbox={handleSelectVariableCheckbox}
    handleColumnNameChange={handleColumnNameChange}
    moveToIndexState={moveToIndexState}
    setMoveToIndexState={setMoveToIndexState}
    draggedRowIndex={draggedRowIndex}
    setDraggedRowIndex={setDraggedRowIndex}
  />
);

const ErrorMessages = ({ errorMessages, classes }) => {
  if (errorMessages?.length)
    return (
      <Container className={classes.validationErrorsContainer}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography className={classes.errorTitle}>
            Resolve following error to continue
          </Typography>
        </div>

        {errorMessages.map((error, index) =>
          typeof error === 'string' ? (
            <ol key={index} style={{ paddingLeft: '13px', marginBottom: '0px', marginTop: '8px' }}>
              <li className={classes.errorContent}>
                <Typography className={classes.errorContent}>{error}</Typography>
              </li>
            </ol>
          ) : typeof error === 'object' ? (
            <div key={index}>
              <ol style={{ paddingLeft: '13px', marginBottom: '0px', marginTop: '8px' }}>
                <li className={classes.errorContent}>
                  Domain '{error.domainNameError}' have {error?.columnNameError?.length} errors,
                  please resolve to proceed.
                </li>
                <ul>
                  {error.columnNameError.map((columnName, i) => (
                    <li key={i} className={classes.errorContent}>
                      Export Column Name "{columnName}" has already been used, provide a different
                      name.
                    </li>
                  ))}
                </ul>
              </ol>
            </div>
          ) : (
            <></>
          )
        )}
      </Container>
    );
  else return <></>;
};

const DomainColumns = (props) => {
  const {
    domainAndColumns,
    setDomainAndColumns,
    includeSystemFields,
    setIncludeSystemFields,
    readOnly,
    validationResult
  } = props;
  const classes = useStyles();
  const [tab, setTab] = useState(0);
  const [selectedDomain, setSelectedDomain] = useState('');
  const [columnNameErrorList, setColumnNameErrorList] = useState([]);

  const [domains, setDomains] = useState([]);
  const [localColumns, setLocalColumns] = useState([]);
  const [moveToIndexState, setMoveToIndexState] = useState(0);
  const [draggedRowIndex, setDraggedRowIndex] = useState(0);
  const [errorMessages, setErrorMessages] = useState([]);
  const mouseRef = useRef();
  const { selectedPreferences } = useSelector((state) => state.PublishMappingSpecData);

  useEffect(() => {
    if (!selectedDomain) {
      const _domains = Object.keys(domainAndColumns || {});
      if (_domains.length) {
        setSelectedDomain(_domains[0]);
      }
    }
  }, [domainAndColumns]);

  useEffect(() => {
    let _domains = [];

    for (let domainCode in domainAndColumns) {
      let domain = domainAndColumns[domainCode];
      if (DOMAIN_TAB[domain.domainType] === tab) {
        _domains.push(domain);
      }
    }

    if (DOMAIN_TAB[domainAndColumns[selectedDomain]?.domainType] !== tab) {
      setSelectedDomain(_domains?.[0]?.domainCode || '');
    }
    setDomains(_domains);
  }, [selectedDomain, tab, readOnly, selectedPreferences]);

  useEffect(() => {
    const message = [];

    if (validationResult?.atleastOneDomainSelected === false)
      message.push('At least one domain should be selected to proceed.');
    for (let errorField in validationResult?.errorFields) {
      message.push({
        domainNameError: errorField,
        columnNameError: Object.keys(validationResult.errorFields[errorField])
      });
      if (selectedDomain === errorField)
        setColumnNameErrorList(Object.keys(validationResult.errorFields[errorField]));
    }

    setErrorMessages(message);
  }, [validationResult, selectedDomain]);

  const { allColumnsChecked, someColumnsChecked } = useMemo(() => {
    let _allColumnsChecked = true;
    let _someColumnsChecked = false;

    for (let column of localColumns) {
      if (!column.isSystemColumn) {
        _allColumnsChecked = _allColumnsChecked && column.checked;
        _someColumnsChecked = _someColumnsChecked || column.checked;
      }
    }

    return {
      allColumnsChecked: _allColumnsChecked,
      someColumnsChecked: !_allColumnsChecked && _someColumnsChecked
    };
  }, [localColumns, includeSystemFields]);

  const { checkedDomainsCount, allDomainsChecked, someDomainsChecked } = useMemo(() => {
    let _checkedDomainsCount = 0;
    let _allDomainsChecked = true;
    let _someDomainsChecked = false;

    for (let domain of domains) {
      if (DOMAIN_TAB[domain.domainType] === tab) {
        _allDomainsChecked = _allDomainsChecked && domain.checked;
        _someDomainsChecked = _someDomainsChecked || domain.checked;
        domain.checked && _checkedDomainsCount++;
      }
    }
    return {
      checkedDomainsCount: _checkedDomainsCount,
      allDomainsChecked: _allDomainsChecked,
      someDomainsChecked: !_allDomainsChecked && _someDomainsChecked
    };
  }, [domains]);

  const userDefinedVarCount = useMemo(() => {
    let _userDefinedVarCount = 0;
    if (selectedDomain) {
      const variables = domainAndColumns[selectedDomain]?.exportVariables.filter(
        (variable) => variable.isSystemColumn !== true
      );
      _userDefinedVarCount = variables.length;
    }

    return _userDefinedVarCount;
  }, [domains, domainAndColumns, selectedDomain, tab]);

  useEffect(() => {
    let _columns = [];
    const _selectedDomain =
      domainAndColumns[selectedDomain]?.domainType &&
      DOMAIN_TAB[domainAndColumns[selectedDomain]?.domainType] !== tab
        ? Object.keys(domainAndColumns || {})[0]
        : selectedDomain;
    if (_selectedDomain) {
      const systemVariables = domainAndColumns[_selectedDomain]?.exportVariables.filter(
        (variable) => variable.isSystemColumn === true
      );
      const variables = domainAndColumns[_selectedDomain]?.exportVariables.filter(
        (variable) => variable.isSystemColumn !== true
      );
      _columns = variables.concat(systemVariables);
    }
    setLocalColumns(_columns);
  }, [selectedDomain, tab, readOnly, selectedPreferences]);

  useEffect(() => {
    if (selectedDomain) {
      const modifiedDomainsAndColumns = domainAndColumns;
      if (modifiedDomainsAndColumns[selectedDomain]?.exportVariables) {
        modifiedDomainsAndColumns[selectedDomain].exportVariables = localColumns;
      }
      setDomainAndColumns(modifiedDomainsAndColumns);
      if (localColumns?.length && localColumns?.every((column) => !column.checked)) {
        const modifiedDomains = [...domains];
        const domainIndex = modifiedDomains.findIndex(
          (_domain) => _domain.domainCode === selectedDomain
        );
        if (domainIndex !== -1) modifiedDomains[domainIndex].checked = false;
        setDomains(modifiedDomains);
      }
    }
  }, [localColumns]);

  useEffect(() => {
    const modifiedDomainsAndColumns = domainAndColumns;
    for (let domain of domains) {
      modifiedDomainsAndColumns[domain.domainCode].checked = domain.checked;
    }
    setDomainAndColumns(modifiedDomainsAndColumns);
  }, [domains]);

  const handleSelectAllDomain = async (e) => {
    const modifiedDomains = [...domains];
    for (let domain of modifiedDomains) {
      domain.checked = e.target.checked;
      domain.exportVariables = domain.exportVariables?.map((column) => ({
        ...column,
        checked: column.isSystemColumn ? column.checked : e.target.checked,
        updatedColumnName: ''
      }));
      if (domain?.domainCode === selectedDomain) setLocalColumns(domain.exportVariables);
    }
    setDomains(modifiedDomains);
  };

  const handleSelectAllVariable = async (e) => {
    const modifiedLocalColumns = [...localColumns];
    for (let column of modifiedLocalColumns) {
      if (!column.isSystemColumn) {
        column.checked = e.target.checked;
        column.updatedColumnName = '';
      }
    }
    setLocalColumns(modifiedLocalColumns);
  };

  const handleSelectDomainCheckbox = async (index) => {
    const modifiedDomains = [...domains];
    modifiedDomains[index].checked = !modifiedDomains[index].checked;
    modifiedDomains[index].exportVariables = modifiedDomains[index].exportVariables?.map(
      (column) => ({
        ...column,
        checked: column.isSystemColumn ? column.checked : modifiedDomains[index].checked,
        updatedColumnName: ''
      })
    );
    if (modifiedDomains[index]?.domainCode === selectedDomain)
      setLocalColumns(modifiedDomains[index]?.exportVariables);

    setDomains(modifiedDomains);
  };

  const handleSelectVariableCheckbox = async (index) => {
    const modifiedLocalColumns = [...localColumns];
    modifiedLocalColumns[index].checked = !modifiedLocalColumns[index].checked;
    modifiedLocalColumns[index].updatedColumnName = '';
    setLocalColumns(modifiedLocalColumns);
  };

  const handleColumnNameChange = async (index, e) => {
    const modifiedLocalColumns = [...localColumns];
    modifiedLocalColumns[index].updatedColumnName = e.target.value;
    setLocalColumns(modifiedLocalColumns);
  };

  const handleChangeTabs = async (event, value) => {
    setTab(value);
    setMoveToIndexState(0);
    setDraggedRowIndex(0);
  };

  const handleToggleChange = (e, checked) => {
    const modifiedLocalColumns = [...localColumns];
    for (let column of modifiedLocalColumns) {
      column.checked = column.isSystemColumn ? checked : column.checked;
    }
    setLocalColumns(modifiedLocalColumns);

    const modifiedDomainsAndColumns = { ...domainAndColumns };
    for (let domainCode in modifiedDomainsAndColumns) {
      modifiedDomainsAndColumns[domainCode].exportVariables = modifiedDomainsAndColumns[
        domainCode
      ].exportVariables?.map((column) => ({
        ...column,
        checked: column.isSystemColumn ? checked : column.checked
      }));
      if (
        modifiedDomainsAndColumns[domainCode].exportVariables?.every((column) => !column.checked)
      ) {
        modifiedDomainsAndColumns[domainCode].checked = false;
      }
    }
    setDomainAndColumns(modifiedDomainsAndColumns);

    setIncludeSystemFields(checked);
  };

  const handleSelectDomain = (domain) => {
    setSelectedDomain(domain.domainCode);
  };

  const handleMoveVariableRow = (moveFrom, moveTo) => {
    console.log('moveFrom, moveTo', moveFrom, moveTo);
    const modifiedLocalColumns = [...localColumns];
    let [rowToMove] = modifiedLocalColumns.splice(moveFrom, 1);
    modifiedLocalColumns.splice(moveTo, 0, rowToMove);
    setLocalColumns(modifiedLocalColumns);
    setMoveToIndexState();
    setDraggedRowIndex();
  };

  const handleCustomDragEnd = (e) => {
    if (mouseRef.current?.buttons === 1 && e.buttons === 0) {
      if (draggedRowIndex !== undefined) {
        if (moveToIndexState < userDefinedVarCount) {
          console.log('moveToIndex', draggedRowIndex, moveToIndexState);
          if (draggedRowIndex !== moveToIndexState)
            handleMoveVariableRow(draggedRowIndex, moveToIndexState);
        }
      }
    }
    mouseRef.current = e;
  };

  return (
    <ConfigSection
      title="Domains & Columns"
      subtitle="Select, rename and reorder the columns according to your preference for the export."
      loading={props.domainAndColumnsLoading}
      hasError={!!errorMessages.length}>
      <div>
        <Grid item xs={12}>
          <ErrorMessages errorMessages={errorMessages} classes={classes} />
        </Grid>
        <Grid
          container
          item
          xs={12}
          style={{ display: 'flex', alignItems: 'center', paddingTop: '10px' }}>
          <span>Include System Fields</span>
          <Tooltip
            variant="dark"
            title={"Enabling this to Include all 'System Fields' from all domains in the export"}
            placement="right"
            style={{ height: '1rem', width: '1rem', marginLeft: '4px' }}>
            <IconButton color="neutral7">
              <Info style={{ width: '16px', maxHeight: '16px' }} />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid container item xs={12}>
          <Tooltip
            title="Enabling this option will include all 'System Fields' columns from all domains in the export."
            placement="right">
            <Switch
              disabled={readOnly}
              size="small"
              data-testid="switch-btn"
              checked={includeSystemFields}
              onChange={handleToggleChange}
            />
          </Tooltip>
        </Grid>
        <Box
          draggable={false}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            padding: '1rem 1rem 1rem 0',
            borderRadius: '5px'
          }}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Card style={{ height: '450px', borderRadius: '8px' }}>
                <Grid container item xs={12}>
                  <Typography
                    sx={{
                      fontSize: '16px',
                      color: neutral8,
                      fontWeight: '600',
                      padding: '1rem 0rem 1rem 1.5rem'
                    }}>
                    {`Select Domain - ${checkedDomainsCount}`}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Tabs
                    value={tab}
                    className="sm-tabs"
                    onChange={handleChangeTabs}
                    truncate
                    data-testid="change-tab">
                    <Tab label="Domains" className="sm-tab" />
                    <Tab label="System Domains" className="sm-tab" />
                    <Tab
                      label="Additional Domains"
                      className="sm-tab"
                      data-testid="Additional-Domains"
                    />
                  </Tabs>
                </Grid>
                <div>
                  <Grid
                    container
                    item
                    xs={12}
                    style={{
                      backgroundColor: '#F6F7FB',
                      height: '44px',
                      display: 'flex',
                      alignItems: 'center'
                    }}>
                    <Grid item xs={2} className={classes.checkBoxClass}>
                      <Checkbox
                        checked={allDomainsChecked}
                        indeterminate={someDomainsChecked}
                        onChange={handleSelectAllDomain}
                        disabled={readOnly}
                        data-testid="allDomainsChecked-checkbox"
                      />
                    </Grid>
                    <Grid item xs={10} className={classes.checkBoxClass}>
                      <Typography
                        sx={{
                          fontSize: '14px',
                          color: neutral8,
                          fontWeight: '400',
                          textAlign: 'left',
                          width: '100%'
                        }}>
                        Domains
                      </Typography>
                    </Grid>
                  </Grid>
                  <Card
                    style={{
                      maxHeight: '300px',
                      position: 'relative',
                      overflow: 'auto',
                      paddingBottom: '0px',
                      boxShadow: 'none'
                    }}>
                    {domains.map((domain, index) => {
                      return (
                        <React.Fragment key={domain.domainCode}>
                          <Divider />
                          <Grid
                            container
                            item
                            xs={12}
                            onClick={() => handleSelectDomain(domain)}
                            sx={{
                              height: '49px',
                              display: 'flex',
                              alignItems: 'center',
                              backgroundColor: selectedDomain === domain.domainCode && '#ECF3FF',
                              cursor: 'pointer',
                              '&:hover': {
                                backgroundColor: selectedDomain !== domain.domainCode && '#ECF3FF95'
                              }
                            }}>
                            <Grid item xs={2} className={classes.checkBoxClass}>
                              <Checkbox
                                data-testid="selectDomain-checkbox"
                                disabled={readOnly}
                                checked={domain.checked}
                                onChange={() => handleSelectDomainCheckbox(index)}
                                onClick={(e) => e.stopPropagation()}
                              />
                            </Grid>
                            <Grid item xs={8} className={classes.checkBoxClass}>
                              <Typography
                                sx={{
                                  fontSize: '14px',
                                  color: neutral8,
                                  fontWeight: '500',
                                  textAlign: 'left',
                                  width: '100%'
                                }}>
                                {domain.domainCode}
                              </Typography>
                            </Grid>
                            {selectedDomain === domain.domainCode && (
                              <Grid item xs={2} className={classes.checkBoxClass}>
                                {!_.isEmpty(validationResult.errorFields) &&
                                domain.domainCode in validationResult.errorFields ? (
                                  <img
                                    alt="error-icon"
                                    style={{ paddingRight: '5px' }}
                                    src={ErrorIcon}
                                  />
                                ) : null}
                                <ChevronRight />
                              </Grid>
                            )}
                          </Grid>
                        </React.Fragment>
                      );
                    })}
                  </Card>
                </div>
              </Card>
            </Grid>
            <Grid item xs={8}>
              <Card
                className={
                  tab === 2 ? classes.additionalDomainColumnCard : classes.domainColumnCard
                }
                style={{ height: '450px', borderRadius: '8px', padding: '0' }}>
                <CardContent style={{ padding: '0px' }}>
                  {tab === 2 ? (
                    <AdditionalDomainsMessage />
                  ) : (
                    <>
                      <Grid container style={{ padding: '20px', BorderBottom: '1px' }}>
                        <Grid item xs={12}>
                          <Typography
                            variant="title2"
                            gutterBottom
                            style={{
                              fontSize: '16px',
                              color: '#000000',
                              fontWeight: '600',
                              lineHeight: '24px'
                            }}>
                            {`Configure Columns from ${selectedDomain}`}
                          </Typography>
                          <Typography
                            variant="body2"
                            gutterBottom
                            style={{
                              fontSize: '14px',
                              color: '#595959',
                              letterSpacing: 0,
                              lineHeight: '24px'
                            }}>
                            Select, rename and reorder the columns according to your preference for
                            the export.
                          </Typography>
                        </Grid>
                      </Grid>
                      <Divider />
                      <Grid
                        container
                        style={{ alignItems: 'center' }}
                        className={classes.columnsHeader}>
                        <Grid xs={1}> </Grid>
                        <Grid xs={1}>
                          <Checkbox
                            data-testid="allColumnsChecked-checkbox"
                            checked={allColumnsChecked}
                            indeterminate={someColumnsChecked}
                            onChange={handleSelectAllVariable}
                            disabled={readOnly || !domainAndColumns[selectedDomain]?.checked}
                          />
                        </Grid>
                        <Grid xs={3} style={{ fontSize: '14px' }}>
                          Column Name
                        </Grid>
                        <Grid xs={3} style={{ fontSize: '14px' }}>
                          Export Column Name
                        </Grid>
                        <Grid xs={4}></Grid>
                      </Grid>

                      <Card
                        style={{
                          maxHeight: '305px',
                          position: 'relative',
                          overflow: 'auto',
                          borderBottomWidth: '0px',
                          boxShadow: 'none',
                          paddingBottom: 0
                        }}
                        width={'100%'}
                        display={'flex'}>
                        <Grid
                          data-testid="grid"
                          item
                          container
                          sx={{ width: '100%', height: '100%' }}
                          onMouseMove={handleCustomDragEnd}>
                          {localColumns.length ? (
                            <List
                              data-testid="list"
                              useIsScrolling
                              height={300}
                              itemCount={localColumns.length}
                              itemSize={50}
                              width={1018}
                              itemData={{
                                localColumns: localColumns,
                                userDefinedVarCount: userDefinedVarCount,
                                rowData: localColumns,
                                readOnly: readOnly || !domainAndColumns[selectedDomain]?.checked,
                                columnNameErrorList: columnNameErrorList,
                                onMoveVariableRow: handleMoveVariableRow,
                                handleSelectVariableCheckbox: handleSelectVariableCheckbox,
                                handleColumnNameChange: handleColumnNameChange,
                                moveToIndexState,
                                setMoveToIndexState,
                                draggedRowIndex,
                                setDraggedRowIndex
                              }}>
                              {ColumnRow}
                            </List>
                          ) : (
                            <></>
                          )}
                        </Grid>
                      </Card>
                    </>
                  )}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </div>
    </ConfigSection>
  );
};

export default DomainColumns;
