/*eslint-disable*/
import Box from 'apollo-react/components/Box';
import Grid from 'apollo-react/components/Grid';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import Button from 'apollo-react/components/Button';
import Card from 'apollo-react/components/Card';
import InfoIcon from 'apollo-react-icons/Info';
import FolderOpen from 'apollo-react-icons/FolderOpen';
import Switch from 'apollo-react/components/Switch';
import Tooltip from 'apollo-react/components/Tooltip';
import Folder from 'apollo-react-icons/Folder';
import Divider from 'apollo-react/components/Divider';
import ArrowRight from 'apollo-react-icons/ArrowRight';
import TextField from 'apollo-react/components/TextField';
import { dateFormatByType } from 'Utils';
import ChevronLeft from 'apollo-react-icons/ChevronLeft';
import Typography from 'apollo-react/components/Typography';
import Arrow2Up from 'apollo-react-icons/Arrow2Up';
import Arrow2Down from 'apollo-react-icons/Arrow2Down';
import Modal from 'apollo-react/components/Modal';
import { Notification } from 'Components/Common/Notification';
import Close from 'apollo-react-icons/Close';
import Check from 'apollo-react-icons/Check';

const useStyles = makeStyles({
  columnsHeader: {
    '& > div': {
      height: '44px',
      border: '1px solid #E9E9E9',
      display: 'flex',
      alignItems: 'center'
    },
    position: 'sticky',
    top: '0px',
    backgroundColor: '#F6F7FB',
    zIndex: 1
  },
  columnName: {
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  },
  row: {
    cursor: 'pointer !important',
    '&:hover': {
      backgroundColor: '#f6f7fb'
    }
  },
  modal: {
    maxWidth: '100%',
    height: '90vh'
  },
  container: {
    maxWidth: '100%',
    '&>div:nth-child(1)': {
      borderBottom: '1px solid #E9E9E9',
      boxShadow: '0px 0px 15px #0002'
    },
    '&>div:nth-child(2)>div:nth-child(1)': {
      borderBottom: '1px solid #E9E9E9'
    },
    '&>div:nth-child(2)': {
      padding: '0px'
    }
  }
});

let specialCharMessageError = 'Special characters are not allowed.';
let duplicateFolderNameMessageError = 'Folder name already exist.';
let folderNameLengthExceedMessageError = 'Maximum 50 characters are allowed.';
let emptyFolderNameError = 'Folder name should have value.';

const FolderManager = (props) => {
  const classes = useStyles();
  const {
    configureFolderModal,
    setConfigureFolderModal,
    handleSaveExportPath,
    handleCancel,
    configureFolderStructures,
    setConfigureFolderStructures
  } = props;
  const [isBatchIdRequired, setIsBatchIdRequired] = useState(false);
  const [currentFolder, setCurrentFolder] = useState(configureFolderStructures[0]?.subFolders);
  const [selectedPath, setSelectedPath] = useState([configureFolderStructures[0]?.name]);
  const [newFolderName, setNewFolderName] = React.useState('');
  const [isCreatingFolder, setIsCreatingFolder] = React.useState(false);
  const [isValid, setIsValid] = useState({
    isSpecialCharExist: false,
    isEmptyFolderName: false,
    isFolderNameLengthExceeds: false,
    isFolderNameExist: false
  });
  const [selectedFolderLocation, setSelectedFolderLocation] = useState({});
  const [isRowSelected, setIsRowSelected] = useState(false);
  const [sortConfig, setSortConfig] = useState({
    key: 'name',
    direction: 'ascending'
  });

  useEffect(() => {
    handleSort();
  }, [sortConfig, currentFolder?.length]);

  const toggleSortOrder = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const handleSort = () => {
    let sortedFolders = [...currentFolder];
    if (sortConfig.key === 'name') {
      sortedFolders?.sort((a, b) => {
        return a.name.toLowerCase() < b.name.toLowerCase() && sortConfig.direction === 'ascending'
          ? -1
          : 1;
      });
    } else if (sortConfig.key === 'LastUpdated') {
      sortedFolders?.sort((a, b) => {
        const dateA = new Date(a.lastModifiedUTC);
        const dateB = new Date(b.lastModifiedUTC);
        return sortConfig.direction === 'ascending' ? dateA - dateB : dateB - dateA;
      });
    }
    setCurrentFolder(sortedFolders);
  };

  const handleRowClick = (event, folder) => {
    event.stopPropagation();
    if (folder && folder.name) {
      setCurrentFolder(folder.subFolders);
      isRowSelected
        ? setSelectedPath([...selectedPath.slice(0, selectedPath.length - 1), folder.name])
        : setSelectedPath([...selectedPath, folder.name]);
      setIsRowSelected(false);
    }
    if (Object.values(folder.subFolders || {}).length === 0) {
      setIsRowSelected(true);
    } else setIsRowSelected(false);
    setIsCreatingFolder(false);
    setIsValid({
      isSpecialCharExist: false,
      isEmptyFolderName: false,
      isFolderNameLengthExceeds: false,
      isFolderNameExist: false
    });
  };

  const handleToggleChange = (e, checked) => {
    setIsBatchIdRequired(checked);
  };

  const handleBack = () => {
    if (selectedPath.length > 1) {
      const newPath =
        isRowSelected && currentFolder.length > 0
          ? selectedPath.slice(0, selectedPath.length - 2)
          : selectedPath.slice(0, selectedPath.length - 1);

      const parentFolder = findFolderByPath(newPath);
      setCurrentFolder(parentFolder.subFolders);
      setSelectedPath(newPath);
    }
    setIsRowSelected(false);
    setSelectedFolderLocation({});
    setSortConfig({ key: '', direction: '' });
    setIsCreatingFolder(false);
    setIsValid({
      isSpecialCharExist: false,
      isEmptyFolderName: false,
      isFolderNameLengthExceeds: false,
      isFolderNameExist: false
    });
  };

  const findFolderByPath = (targetPath) => {
    let current = configureFolderStructures[0];
    for (let i = 1; i < targetPath.length; i++) {
      const folderName = targetPath[i];
      const foundFolder = current.subFolders.find((folder) => folder.name === folderName);
      if (foundFolder) {
        current = foundFolder;
      } else {
        break;
      }
    }
    return current;
  };

  const isNewFolderInPath = (selectedPath) => {
    const targetPath = selectedPath.slice();
    let current = configureFolderStructures[0];
    for (let i = 1; i < targetPath.length; i++) {
      const folderName = targetPath[i];
      const foundFolder = current.subFolders.find((folder) => folder.name === folderName);
      if (foundFolder) {
        current = foundFolder;
      } else {
        return {
          hasNewFolder: true,
          existingFolderPath: targetPath.slice(0, i),
          newFolderPath: targetPath.slice(i)
        };
      }
    }
    return false;
  };

  const handleSave = () => {
    const isNewFolderCreated = isNewFolderInPath(selectedPath);
    handleSaveExportPath(selectedPath, isBatchIdRequired, isNewFolderCreated);
    setConfigureFolderStructures([]);
    setIsValid({
      isSpecialCharExist: false,
      isEmptyFolderName: false,
      isFolderNameLengthExceeds: false,
      isFolderNameExist: false
    });
  };

  const handleClose = () => {
    setConfigureFolderModal(false);
    setConfigureFolderStructures([]);
    setIsValid({
      isSpecialCharExist: false,
      isEmptyFolderName: false,
      isFolderNameLengthExceeds: false,
      isFolderNameExist: false
    });
  };

  const handleKeyPress = () => {
    const regex = /[~`'+=;/!@#$%^&*(),-.?":{}|<>[\]\\]/;
    const checkSpecialChar = regex.test(newFolderName);
    const isFolderNamePresent = currentFolder?.some((folder) => folder.name === newFolderName);
    if (checkSpecialChar) {
      setIsValid({
        isSpecialCharExist: true,
        isEmptyFolderName: false,
        isFolderNameLengthExceeds: false,
        isFolderNameExist: false
      });
    } else if (isFolderNamePresent) {
      setIsValid({
        isSpecialCharExist: false,
        isEmptyFolderName: false,
        isFolderNameLengthExceeds: false,
        isFolderNameExist: true
      });
    } else if (newFolderName === '' || newFolderName === undefined) {
      setIsValid({
        isSpecialCharExist: false,
        isEmptyFolderName: true,
        isFolderNameLengthExceeds: false,
        isFolderNameExist: false
      });
    } else if (newFolderName.length > 50) {
      setIsValid({
        isSpecialCharExist: false,
        isEmptyFolderName: false,
        isFolderNameLengthExceeds: true,
        isFolderNameExist: false
      });
    } else {
      const newFolder = {
        name: newFolderName,
        lastModifiedUTC: new Date().toISOString(),
        subFolders: []
      };
      setCurrentFolder([...currentFolder, newFolder]);
      setNewFolderName('');
      setIsCreatingFolder(false);
      setIsValid({
        isSpecialCharExist: false,
        isEmptyFolderName: false,
        isFolderNameLengthExceeds: false,
        isFolderNameExist: false
      });
      setIsRowSelected(true);
      setSelectedPath([...selectedPath, newFolderName]);
      setSelectedFolderLocation(newFolder);
    }
  };

  const handleNewFolderClose = () => {
    setIsCreatingFolder(false);
    setIsValid({
      isSpecialCharExist: false,
      isEmptyFolderName: false,
      isFolderNameLengthExceeds: false,
      isFolderNameExist: false
    });
  };

  const handleNewFolderNameChange = (event) => {
    setNewFolderName(event.target.value);
  };

  const handleCreateFolder = () => {
    setNewFolderName('');
    setIsCreatingFolder(true);
  };

  const handleRowSelect = (folder) => {
    isRowSelected
      ? setSelectedPath([...selectedPath.slice(0, selectedPath.length - 1), folder.name])
      : setSelectedPath([...selectedPath, folder.name]);
    setSelectedFolderLocation(folder);
    setIsRowSelected(true);
  };

  return (
    <Modal
      open={configureFolderModal}
      onClose={() => handleClose()}
      title={'Configure Folder'}
      subtitle={
        'Set up automated data exports to maintain timely and accurate information effortlessly.'
      }
      hideButtons
      id="customButtons"
      width={classes.modal}
      className={classes.container}>
      <div style={{ height: '100%', width: '1050px' }}>
        <Box style={{ width: '100%', padding: '10px 20px 0 20px' }}>
          <Grid xs={12}>
            <Box>
              <Grid
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  padding: '10px 0px'
                }}>
                <Grid style={{ display: 'flex', alignItems: 'center' }} xs={6}>
                  <FolderOpen />
                  <span style={{ paddingLeft: '10px' }}>{selectedPath?.join('/')}</span>
                </Grid>
                <Grid>
                  <Button
                    variant="secondary"
                    disabled={isCreatingFolder}
                    onClick={handleCreateFolder}>
                    Create Folder
                  </Button>
                </Grid>
              </Grid>
              {selectedPath.length > 1 &&
              !(selectedPath.length === 2 && isRowSelected && currentFolder.length > 0) ? (
                <Grid
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    paddingBottom: '10px'
                  }}>
                  <Button icon={<ChevronLeft />} variant="secondary" onClick={() => handleBack()}>
                    Back
                  </Button>
                </Grid>
              ) : null}
              <Notification
                variant={'info'}
                message={
                  'Folder will be created in the destination path once the data product is published.'
                }
              />
            </Box>

            <Card
              style={{
                height:
                  selectedPath.length > 1 &&
                  !(selectedPath.length === 2 && isRowSelected && currentFolder.length > 0)
                    ? 'calc(30vh-50px)'
                    : '30vh',
                position: 'relative',
                overflow: 'auto',
                paddingBottom: '0px',
                boxShadow: 'none'
              }}>
              <Grid container className={classes.columnsHeader}>
                <Grid item xs={12}>
                  <Box style={{ width: '100%', height: '44px' }} display={'flex'}>
                    <Grid container style={{ alignItems: 'center' }}>
                      <Grid xs={1}></Grid>
                      <Grid
                        xs={4}
                        className={classes.columnName}
                        onClick={() => toggleSortOrder('name')}>
                        Name
                        {sortConfig.key === 'name' && (
                          <div>
                            {sortConfig.direction === 'ascending' ? (
                              <Arrow2Down fontSize={'small'} />
                            ) : (
                              <Arrow2Up fontSize={'small'} />
                            )}
                          </div>
                        )}
                      </Grid>
                      <Grid
                        xs={3}
                        className={classes.columnName}
                        onClick={() => toggleSortOrder('LastUpdated')}>
                        Last Updated
                        {sortConfig.key === 'LastUpdated' && (
                          <div>
                            {sortConfig.direction === 'ascending' ? (
                              <Arrow2Down fontSize={'small'} />
                            ) : (
                              <Arrow2Up fontSize={'small'} />
                            )}
                          </div>
                        )}
                      </Grid>
                      <Grid xs={4}></Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
              <Grid
                container
                sx={{ position: 'sticky', top: '44px', zIndex: 1, backgroundColor: '#fff' }}>
                <Grid xs={1} style={{ display: 'flex', justifyContent: 'center' }}></Grid>
                <Grid xs={4} sx={{ display: 'flex', alignItems: 'center', pr: '10px' }}>
                  {isCreatingFolder && (
                    <TextField
                      fullWidth
                      value={newFolderName}
                      onChange={handleNewFolderNameChange}
                      onKeyPress={(e) => e.key === 'Enter' && handleKeyPress()}
                      style={{ marginTop: '3px' }}
                      helperText={
                        isValid.isFolderNameExist
                          ? duplicateFolderNameMessageError
                          : isValid.isSpecialCharExist
                          ? specialCharMessageError
                          : isValid.isFolderNameLengthExceeds
                          ? folderNameLengthExceedMessageError
                          : isValid.isEmptyFolderName && emptyFolderNameError
                      }
                      error={Object.values(isValid).some((el) => el === true)}
                    />
                  )}
                </Grid>
                <Grid xs={3} style={{ display: 'flex', alignItems: 'center' }}>
                  {isCreatingFolder && (
                    <Box style={{ display: 'flex', alignItems: 'center' }}>
                      <Tooltip title="Create">
                        <Button
                          variant="secondary"
                          icon={Check}
                          sx={{
                            mr: '10px',
                            '& svg': {
                              mx: 0
                            },
                            minWidth: '40px',
                            p: 0,
                            color: 'green'
                          }}
                          status="success"
                          onClick={handleKeyPress}></Button>
                      </Tooltip>
                      <Tooltip title="Cancel">
                        <Button
                          variant="secondary"
                          icon={Close}
                          sx={{
                            '& svg': {
                              mx: 0
                            },
                            minWidth: '40px',
                            p: 0,
                            color: 'red'
                          }}
                          status="danger"
                          onClick={handleNewFolderClose}></Button>
                      </Tooltip>
                    </Box>
                  )}
                </Grid>
              </Grid>
              {currentFolder &&
                currentFolder.map((folder) => {
                  return (
                    <Grid
                      container
                      onClick={() => handleRowSelect(folder)}
                      onDoubleClick={(e) => handleRowClick(e, folder)}
                      className={classes.row}>
                      <Grid item xs={12}>
                        <Box
                          style={{
                            width: '100%',
                            height: '40px',
                            backgroundColor:
                              selectedFolderLocation.name === folder.name && '#ECF3FF'
                          }}
                          display={'flex'}>
                          <Grid
                            container
                            style={{
                              alignItems: 'center',
                              maxHeight: '300px',
                              position: 'relative',
                              overflow: 'auto'
                            }}>
                            <Grid xs={1} style={{ display: 'flex', justifyContent: 'center' }}>
                              <Folder style={{ color: '#B3D1FE' }} />
                            </Grid>
                            <Grid xs={4}>
                              <Typography noWrap style={{ fontSize: '14px', maxWidth: '95%' }}>
                                {folder.name}
                              </Typography>
                            </Grid>
                            <Grid xs={3} style={{ fontSize: '14px' }}>
                              {dateFormatByType(folder.lastModifiedUTC, 'Table')}
                            </Grid>
                            <Grid xs={3}></Grid>
                            <Grid
                              xs={1}
                              onClick={(event) => handleRowClick(event, folder)}
                              style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                paddingRight: '1rem'
                              }}>
                              <ArrowRight />
                            </Grid>
                          </Grid>
                        </Box>
                        <Divider />
                      </Grid>
                    </Grid>
                  );
                })}
            </Card>

            <Grid
              container
              item
              xs={12}
              style={{ display: 'flex', alignItems: 'center', paddingTop: '10px' }}>
              <span>Create Batch ID specific sub folder for every export</span>
              <Tooltip
                title="Automatically create subfolder based on unique “Batch ID”."
                placement="right">
                <InfoIcon />
              </Tooltip>
            </Grid>
            <Grid container item xs={12} style={{ paddingBottom: '20px' }}>
              <Switch size="small" checked={isBatchIdRequired} onChange={handleToggleChange} />
            </Grid>
          </Grid>
        </Box>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          margin: '15px 20px',
          padding: '0px'
        }}>
        <Button
          className={classes.customButton}
          onClick={handleCancel}
          style={{ margin: '0px 10px' }}
          variant="secondary">
          Cancel
        </Button>
        <Tooltip title={isCreatingFolder && 'Create folder in progress'} placement="top">
          <span style={{ display: 'inline-block' }}>
            <Button variant="primary" disabled={isCreatingFolder} onClick={handleSave}>
              Save
            </Button>
          </span>
        </Tooltip>
      </div>
    </Modal>
  );
};

export default FolderManager;
